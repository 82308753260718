<template>
  <div :class="$style.root">
    <VRow dense>
      <VCol cols="12">
        <VTextField label="Фамилия Имя Отчество" :value="value.fio" @input="fio => $emit('input', {...value, fio })" :rules="rules" outlined dense hideDetails="auto" />
      </VCol>
      <VCol cols="12" md="4">
        <VTextField label="Должность" :value="value.position" @input="position => $emit('input', {...value, position })" :rules="rules" outlined dense hideDetails="auto" />
      </VCol>
      <VCol cols="12" md="4">
        <VTextField label="E-mail" :value="value.email" @input="email => $emit('input', {...value, email })" :rules="[...rules, (v) => (!v || !!new RegExp('^[A-zА-я0-9_.+-]+@[A-zА-я0-9-]+\\.[A-zА-я0-9-.]+$').test(v)) || 'Укажите корректный email']" outlined dense hideDetails="auto" autocomplete="chrome-off" />
      </VCol>
      <VCol cols="12" md="4">
        <PhoneField label="Телефон" :value="value.phone" @input="phone => $emit('input', {...value, phone })" :rules="rules" />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import PhoneField from './PhoneField';
export default {
  name: 'Person',
  components: {
    PhoneField,
  },
  props: {
    required: { type: Boolean },
    value: {
      type: Object,
      default: () => ({
        fio: '',
        position: '',
        email: '',
        phone: '',
      }),
    }
  },
  computed: {
    rules() {
      if (this.required) return [(v) => !!v || 'Обязательно для заполнения'];
      return [];
    },
  },
}
</script>

<style module lang="scss">
.root{}
</style>
